import gql from 'graphql-tag';

const containersCreate = gql`
  mutation containersCreate(
    $deliveryId: GUID!
    $depth: Float!
    $hasPallet: Boolean
    $height: Float!
    $isBio: Boolean
    $isRefrigerated: Boolean
    $isStackable: Boolean
    $isPacked: Boolean
    $name: String!
    $quantity: Float
    $temperature: Float
    $type: String
    $weight: Float!
    $width: Float!
  ) {
    containersCreate(
      deliveryId: $deliveryId
      depth: $depth
      hasPallet: $hasPallet
      height: $height
      isBio: $isBio
      isRefrigerated: $isRefrigerated
      isStackable: $isStackable
      isPacked: $isPacked
      name: $name
      quantity: $quantity
      temperature: $temperature
      type: $type
      weight: $weight
      width: $width
    ) {
      id
    }
  }
`;

const containersUpdate = gql`
  mutation containersUpdate(
    $depth: Float
    $hasPallet: Boolean
    $height: Float
    $id: GUID!
    $isBio: Boolean
    $isRefrigerated: Boolean
    $isStackable: Boolean
    $isPacked: Boolean
    $name: String
    $quantity: Float
    $temperature: Float
    $type: String
    $weight: Float
    $width: Float
  ) {
    containersUpdate(
      depth: $depth
      hasPallet: $hasPallet
      height: $height
      id: $id
      isBio: $isBio
      isRefrigerated: $isRefrigerated
      isStackable: $isStackable
      isPacked: $isPacked
      name: $name
      quantity: $quantity
      temperature: $temperature
      type: $type
      weight: $weight
      width: $width
    ) {
      id
    }
  }
`;

const containersDelete = gql`
  mutation containersDelete($id: GUID!) {
    containersDelete(id: $id)
  }
`;

export { containersCreate, containersDelete, containersUpdate };
