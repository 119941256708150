import { Form } from 'dataformjs';
import { navigate } from 'gatsby';
import React, { FC } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import GridSplit from '../../../components/base/GridSplit';
import containerData from '../../../objects/containers/datas.json';
import { containersCreate as createMutation } from '../../../objects/containers/mutations';
import pageProps, { ResultProps } from '../../../utils/pageProps';
import requiredAuth from '../../../utils/requireAuth';

const ContainerCreatePage: FC<ResultProps> = ({
  params: { delivery: deliveryId },
}) => {
  const formName = 'container';
  const [createMutationFunc] = useMutation(createMutation);
  const { t } = useTranslation();

  const handleOnSubmit = async (values: any) => {
    const result = await createMutationFunc({
      variables: {
        deliveryId,
        depth:
          '' !== values.depth && !Number.isNaN(Number(values.depth))
            ? Number(values.depth)
            : undefined,
        hasPallet: Boolean(values.hasPallet),
        height:
          '' !== values.height && !Number.isNaN(Number(values.height))
            ? Number(values.height)
            : undefined,
        isBio: Boolean(values.isBio),
        isPacked: Boolean(values.isPacked),
        isRefrigerated: Boolean(values.isRefrigerated),
        isStackable: Boolean(values.isStackable),
        name: values.name,
        quantity:
          '' !== values.quantity && !Number.isNaN(Number(values.quantity))
            ? parseInt(values.quantity, 10)
            : undefined,
        temperature:
          '' !== values.temperature && !Number.isNaN(Number(values.temperature))
            ? Number(values.temperature)
            : undefined,
        type: values.type,
        weight:
          '' !== values.weight && !Number.isNaN(Number(values.weight))
            ? Number(values.weight)
            : undefined,
        width:
          '' !== values.width && !Number.isNaN(Number(values.width))
            ? Number(values.width)
            : undefined,
      },
    });

    if (!result || result.errors) {
      console.error(result.errors);
    } else {
      const containerId = result.data.containersCreate.id;
      window.history.replaceState(
        {
          containerId,
          deliveryId,
        },
        '',
        `/admin/deliveries/update/${deliveryId}/`,
      );
      navigate(`/admin/containers/update/${containerId}/`);
    }
  };

  return (
    <GridSplit
      header={{
        back: {
          onClick: () => {
            window.history.back();
          },
          text: t(`back`),
        },
        title: t(`container.form.create.title`),
      }}
    >
      <Form
        className="mx-6 mb-6"
        datas={containerData}
        footerProps={{
          className: 'mt-12 flex justify-end',
        }}
        name={formName}
        onSubmit={handleOnSubmit}
        submitProps={{
          label: t(`container.form.create.submit`),
        }}
      />
    </GridSplit>
  );
};

export default requiredAuth(pageProps(ContainerCreatePage), {
  admin: true,
  layout: true,
});
